import React from 'react'
import BreedSelector from '../breed/BreedSelector'
import { BreedContext } from '../breed/BreedProvider'
import { LanguageContext } from '../localization/LanguageProvider'
import { BreedClassContext } from '../breed/BreedClassContext'
import Display from './Display'

class PetAgeCalculator extends React.Component {
  constructor(props) {
    super(props)

    const isBreedSelectorValid = (breed) => {
      return breed !== null;
    }

    this.state = {
      formFields: {
        breedSelector: {
          value: null,
          isValid: null,
          validationFn: isBreedSelectorValid,
          isRequired: true
        },
        age: {
          value: null,
          isValid: null,
          validationFn: null,
          mappingFn: null,
          isRequired: true
        }
      },
      calculatedAge: null
    }
  }

  render() {
    const { CalculatorComponent } = this.props

    const getWeightClass = (breeds, breedClasses, selectedBreed) => {
      const isWeightWithinRange = (breedClass, averageWeight) => {
        return averageWeight >= breedClass.imperial.fromWeight && averageWeight <= breedClass.imperial.toWeight
      }

      const getAverageWeight = (minWeight, maxWeight) => {
        return (minWeight + maxWeight) / 2
      }

      if (selectedBreed) {
        const breedWeightImperial = breeds.find(breedObj => breedObj.name === selectedBreed).weight.imperial
        const minWeight = Number.parseInt(breedWeightImperial.split('-')[0].trim())
        const maxWeight = Number.parseInt(breedWeightImperial.split('-')[1].trim())
        const averageBreedWeight = getAverageWeight(minWeight, maxWeight)
        const breedClass = breedClasses.find(breedClass => isWeightWithinRange(breedClass, averageBreedWeight))

        return breedClass
      } else {
        return null
      }
    }


    return (
      <LanguageContext.Consumer>
        {({ currentLanguage, isTranslationLoaded, translationFn }) => {
          return (<div className="age-calculator-container">
            <div className="age-calculator-form-container">
              <BreedSelector
                placeholder={translationFn('tabs.chooseBreedTxt')}
                onValueSelected={this.setBreed.bind(this)}
                selectedValue={this.state.formFields.breedSelector.value}
                isValid={this.state.formFields.breedSelector.isValid} />
              {React.cloneElement(CalculatorComponent, {
                ageValue: this.state.formFields.age.value,
                isAgeValid: this.state.formFields.age.isValid,
                onAgeChanged: this.setPetAge.bind(this),
              })}
              <div className="age-calculator-buttons">
                {<BreedContext.Consumer>
                  {({ requestStatus, breeds }) => {
                    return (
                      <BreedClassContext.Consumer>
                        {({ breedClasses, ageCalcFn }) => {
                          const petWeightClass = getWeightClass(breeds, breedClasses, this.state.formFields.breedSelector.value)
                          const petAge = this.state.formFields.age.mappingFn ? 
                            this.state.formFields.age.mappingFn(this.state.formFields.age.value) :
                            this.state.formFields.age.value

                          return (<button className="age-calculator-button" onClick={() =>
                            this.calculate(petWeightClass, petAge, ageCalcFn)}>
                            {translationFn('tabs.byage.calculator.calculateBtnTxt')}
                          </button>)
                        }}
                      </BreedClassContext.Consumer>
                    );
                  }}
                </BreedContext.Consumer>}
                <button onClick={() => this.clear()}>
                  {translationFn('tabs.clearBtnTxt')}
                </button>
              </div>
            </div>
            <Display calculatedAge={this.state.calculatedAge} />
          </div>)
        }}
      </LanguageContext.Consumer>)
  }

  setBreed(breed) {
    this.setState((prevState) => ({
      formFields: {
        breedSelector: {
          value: breed,
          isValid: prevState.formFields.breedSelector.validationFn(breed),
          validationFn: prevState.formFields.breedSelector.validationFn,
          isRequired: prevState.formFields.breedSelector.isRequired
        },
        age: prevState.formFields.age
      }
    }))
  }

  setCalculatedAge(age) {
    this.setState({ calculatedAge: age });
  }

  setPetAge(value, validationFn, mappingFn) {
    this.setState((prevState) => ({
      formFields: {
        breedSelector: prevState.formFields.breedSelector,
        age: {
          value: value,
          isValid: !value ? null : validationFn ? validationFn(value) : true,
          validationFn: validationFn ? validationFn : prevState.formFields.age.validationFn,
          mappingFn: mappingFn ? mappingFn : prevState.formFields.age.mappingFn,
          isRequired: prevState.formFields.age.isRequired
        }
      }
    }))
  }

  calculate(breedClass, petAge, ageFn) {
    const isFormValid = () => {
      return Object.keys(this.state.formFields).filter(key => this.state.formFields[key].isRequired)
        .every(key => this.state.formFields[key].isValid)
    }

    const checkFormUninitialized = () => {
      this.setState((prevState) => {
        const formFields = {}

        Object.keys(prevState.formFields).forEach(key => {
          const isUninitialized = !prevState.formFields[key].value 

          const isRequired = prevState.formFields[key].isRequired ? 
            prevState.formFields[key].isRequired : false;

          formFields[key] = {
            value: prevState.formFields[key].value,
            isValid: isRequired && isUninitialized ? false : prevState.formFields[key].isValid,
            validationFn: prevState.formFields[key].validationFn,
            mappingFn: prevState.formFields[key].mappingFn,
            isRequired: prevState.formFields[key].isRequired
          }
        });

        return { formFields: formFields };
      });
    }

    if (isFormValid()) {
      const calculatedAge = ageFn(breedClass.className, petAge)
      this.setCalculatedAge(calculatedAge);
    } else {
      checkFormUninitialized();
    }
  }

  clear() {
    this.setState((prevState) => ({
      formFields: {
        breedSelector: {
          value: null,
          isValid: null,
          validationFn: prevState.formFields.breedSelector.validationFn,
          isRequired: prevState.formFields.breedSelector.isRequired
        },
        age: {
          value: null,
          isValid: null,
          validationFn: prevState.formFields.age.validationFn,
          mappingFn: prevState.formFields.age.mappingFn,
          isRequired: prevState.formFields.age.isRequired
        }
      },
      calculatedAge: null
    }));
  }
}

export default PetAgeCalculator