import React from 'react'
import classNames from 'classnames'
import { BreedContext } from './BreedProvider'
import SelectSearch, { fuzzySearch } from 'react-select-search'

const BreedSelector = (props) => {
  const breedSelectorClassName = classNames({
    'form-field': true,
    'is-required-form-field': props.isValid !== null ? !props.isValid : false
  });

  return (
    <BreedContext.Consumer>
      {({ requestStatus, breeds }) => {
        return (
          <div className="breed-selector-container">
            <div className={breedSelectorClassName}>
              <SelectSearch
                options={breeds.map(breed => { return { name: breed.name, value: breed.name } })}
                search={true}
                filterOptions={fuzzySearch}
                placeholder={props.placeholder}
                onChange={props.onValueSelected}
                closeOnSelect={true}
                value={props.selectedValue}
              />
            </div>
          </div>
        )
      }}
    </BreedContext.Consumer>
  )
}

export default BreedSelector