import classNames from "classnames";
import { Component } from "react";

class Banner extends Component {
  render() {
    const { isActive } = this.props;

    const bannerClassNames = classNames({
      'hidden': !isActive,
      'advertisement-banner': true
    });

    return (
      <div className={bannerClassNames}></div>
    )
  }
}

export default Banner