import { Component } from 'react'
import Header from '../header/Header'
import Banner from '../advertising/Banner'
import { BreedProvider } from '../breed/BreedProvider'
import { LanguageProvider } from '../localization/LanguageProvider'
import { withTranslation } from 'react-i18next'
import TabbedCalculator from '../calculator/TabbedCalculator'
import Footer from '../footer/Footer'

const requestStatus = {
    'STALE': 0,
    'IN_PROGRESS': 1,
    'SUCCEEDED': 2,
    'FAILED': 3
}

class App extends Component {
  static defaultProps = {
    isGoogleAdSenseEnabled: process.env.REACT_APP_IS_GOOGLE_AD_SENSE_ENABLED,
    dogApiHost: process.env.REACT_APP_DOG_API_HOST
  }

    constructor(props) {
        super(props)

        this.state = {
            currentLanguage: localStorage.getItem('i18nextLng'),
            breedRequestStatus: requestStatus.STALE,
            breeds: []
        }
    }

    render() {
        return (
            <LanguageProvider value={{ currentLanguage: this.state.currentLanguage, isTranslationLoaded: this.props.tReady, translationFn: this.props.t }}>
                <BreedProvider value={{ requestStatus: this.state.requestStatus, breeds: this.state.breeds }}>
                    <Header updateLanguageFn={this.updateLanguage.bind(this)} />
                    <div className="content">
                        <Banner isActive={this.props.isGoogleAdSenseEnabled === true}/>
                        <TabbedCalculator />
                    </div>
                    <Footer updateLanguageFn={this.updateLanguage.bind(this)}></Footer>
                </BreedProvider>
            </LanguageProvider>
        )
    }

    componentDidMount() {
        const breedsRequestHeaders = new Headers()

        const breedsRequest = new Request(`${this.props.dogApiHost}/v1/breeds`, {
            method: 'GET',
            mode: 'cors',
            cache: 'default'
        })

        this.setState({ breedRequestStatus: requestStatus.IN_PROGRESS }, () => {
            fetch(breedsRequest)
                .then(res => res.json())
                .then(data => this.setState({ breedRequestStatus: requestStatus.SUCCEEDED, breeds: data }))
                .catch(_ => this.setState({ breedRequestStatus: requestStatus.FAILED }))
        })
    }

    updateLanguage(selectedLanguage) {
        this.props.i18n.changeLanguage(selectedLanguage)
        this.setState({ currentLanguage: selectedLanguage })
    }
}

const LocalizedApp = withTranslation()(App)

export default LocalizedApp