import { Component } from 'react'
import classNames from 'classnames'

class TabContent extends Component {
    render() {
        const { isSelected } = this.props
        const { children } = this.props

        const tabContentClassNames = classNames({
            'tab-content': true,
            'hidden': !isSelected
        })

        return (
            <div className={tabContentClassNames}>
                {children}
            </div>
        )
    }
}

export default TabContent