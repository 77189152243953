import { LanguageContext } from "../localization/LanguageProvider";
import { Component } from "react";

class Footer extends Component {
  render() {
    const { updateLanguageFn } = this.props

    return (
      <LanguageContext.Consumer>
        {({ currentLanguage, isTranslationLoaded, translationFn }) => {
          return (<footer className="footer">
            <div className="languages">
              <p className="language-list-title">{translationFn("footer.languageTitleTxt")}</p>
              <ul className="language-list">
                <li className="language pt-BR"
                  data-language="pt"
                  onClick={(event) => { updateLanguageFn(this.getLanguageListItemFromChildNode(event.target).getAttribute('data-language')) }}
                >
                  <img
                    src="/img/language-pt-BR.svg"
                  />
                </li>
                <li className="language en-US"
                  data-language="en"
                  onClick={(event) => updateLanguageFn(this.getLanguageListItemFromChildNode(event.target).getAttribute('data-language'))}
                >
                  <img
                    src="/img/language-en-US.svg"
                  />
                </li>
              </ul>
            </div>
            <p className="copyright">
              {translationFn("footer.copyrightTxt")}
            </p>
          </footer>)
        }}
      </LanguageContext.Consumer>
    )
  }

  getLanguageListItemFromChildNode(node) {
    if (node.tagName === 'LI' && node.getAttribute('data-language') !== null) {
      return node;
    }

    return this.getLanguageListItemFromChildNode(node.parentElement);
  }
}

export default Footer