import React from "react"

export const BreedClassContext = React.createContext({
  breedClasses: [
    {
      className: 'small',
      imperial: {
        fromWeight: Number.MIN_SAFE_INTEGER,
        toWeight: 20
      }
    },
    {
      className: 'medium',
      imperial: {
        fromWeight: 21,
        toWeight: 50
      }
    },
    {
      className: 'large',
      imperial: {
        fromWeight: 51,
        toWeight: 100
      }
    },
    {
      className: 'giant',
      imperial: {
        fromWeight: 101,
        toWeight: Number.MAX_SAFE_INTEGER
      }
    }
  ],
  ageCalcFn: (breedClass, petAge) => {
    const ageMap = {
      "small-0": 0, "small-1": 15, "small-2": 24, "small-3": 28, "small-4": 32,
      "small-5": 36, "small-6": 40, "small-7": 44, "small-8": 48, "small-9": 52, "small-10": 56,
      "small-11": 60, "small-12": 64, "small-13": 68, "small-14": 72, "small-15": 76, "small-16": 80,
      "medium-0": 0, "medium-1": 15, "medium-2": 24, "medium-3": 28, "medium-4": 32, "medium-5": 36,
      "medium-6": 42, "medium-7": 47, "medium-8": 51, "medium-9": 56, "medium-10": 60, "medium-11": 65,
      "medium-12": 69, "medium-13": 74, "medium-14": 78, "medium-15": 83, "medium-16": 87, "large-0": 0,
      "large-1": 15, "large-2": 24, "large-3": 28, "large-4": 32, "large-5": 36, "large-6": 45, "large-7": 50,
      "large-8": 55, "large-9": 61, "large-10": 66, "large-11": 72, "large-12": 77, "large-13": 82, "large-14": 88,
      "large-15": 93, "large-16": 99, "giant-0": 0, "giant-1": 12, "giant-2": 22, "giant-3": 31, "giant-4": 38,
      "giant-5": 45, "giant-6": 49, "giant-7": 56, "giant-8": 64, "giant-9": 71, "giant-10": 79, "giant-11": 86,
      "giant-12": 93, "giant-13": 100, "giant-14": 107, "giant-15": 114, "giant-16": 121
    }

    const getAgingRate = (petAge, breedClass, ageMap) => {
      const petAgeYears = petAge.years
      const expectedHumanAgeNextPetAgeInYears = ageMap[`${breedClass}-${petAgeYears + 1}`]
      const humanAgeAtPetAgeInYears = ageMap[`${breedClass}-${petAgeYears}`]

      if (expectedHumanAgeNextPetAgeInYears !== undefined && humanAgeAtPetAgeInYears !== undefined) {
        return expectedHumanAgeNextPetAgeInYears - humanAgeAtPetAgeInYears
      } else {
        const lastKnowHumanAges = Object.keys(ageMap).filter(key => key.includes(breedClass))
          .sort().slice(-2).map(key => ageMap[key])

        return lastKnowHumanAges[1] - lastKnowHumanAges[0]
      }
    }

    const getLastMappablePetAge = (petAge, breedClass, ageMap) => {
      const petAgeInYears = petAge.years
      const humanAgeAtPetAgeInYears = ageMap[`${breedClass}-${petAge.years}`]

      if (humanAgeAtPetAgeInYears !== undefined) {
        return petAgeInYears
      }

      const lastKnownHumanAge = Object.keys(ageMap).filter(key => key.includes(breedClass))
        .sort((a, b) => a.split("-")[1] - b.split("-")[1]).slice(-1)[0]

      return lastKnownHumanAge.split("-")[1]
    }

    const lastKnownPetAge = getLastMappablePetAge(petAge, breedClass, ageMap)
    const humanAgeInMonths = ((ageMap[`${breedClass}-${lastKnownPetAge}`] * 12) +
      (((petAge.years - lastKnownPetAge) * 12 + petAge.months) * getAgingRate(petAge, breedClass, ageMap)))
    const petHumanAgeInYears = Math.trunc(humanAgeInMonths / 12)
    const petHumanAgeInMonths = humanAgeInMonths % 12

    const result = { years: petHumanAgeInYears, months: petHumanAgeInMonths }

    return result
  }
})