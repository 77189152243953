import classNames from "classnames";
import CountUp from 'react-countup';
import { Component } from "react";
import { LanguageContext } from "../localization/LanguageProvider";

class Display extends Component {
  render() {
    const { calculatedAge } = this.props
    const dataContainerClassNames = classNames({
      'age-calculator-result-content-container': true,
      'hidden': calculatedAge === null
    })

    return (
      <LanguageContext.Consumer>
        {({ currentLanguage, isTranslationLoaded, translationFn }) => {
          const humanYears = calculatedAge ? calculatedAge.years : 0
          const humanMonths = calculatedAge ? calculatedAge.months : 0

          return (<div className="age-calculator-result-container">
            <div className={dataContainerClassNames}>
              <div className="age-calculator-result-title">
                <span>{translationFn("tabs.resultTitleLabelTxt")}</span>
              </div>
              <div className="age-calculator-result-data-container">
                <div className="age-calculator-result-count-up-container">
                  <CountUp className="age-calculator-result-count-up" end={humanYears} duration={1} />
                  <span className="age-calculator-result-count-up-label">{translationFn("tabs.resultYearCounterLabelTxt")}</span>
                </div>
                <div className="age-calculator-result-count-up-container">
                  <CountUp className="age-calculator-result-count-up" end={humanMonths} duration={1} />
                  <span className="age-calculator-result-count-up-label">{translationFn("tabs.resultMonthCounterLabelTxt")}</span>
                </div>
              </div>
            </div>
          </div>)
        }}
      </LanguageContext.Consumer>
    )
  }
}

export default Display