import classNames from 'classnames'
import ReactSlider from 'react-slider'
import { Component } from 'react'
import { LanguageContext } from '../localization/LanguageProvider'

class ByActualAgePetAgeCalculator extends Component {
  static defaultProps = {
    minYearSliderValue: 0,
    maxYearSliderValue: 30,
    minMonthSliderValue: 0,
    maxMonthSliderValue: 11
  }

  render() {
    const { ageValue } = this.props
    const { isAgeValid } = this.props
    const { onAgeChanged } = this.props

    const isMonthSliderDisabled = ageValue && ageValue.years ? 
      ageValue.years === this.props.maxYearSliderValue : false

    const titleClassNames = classNames({
      "by-actual-age-calculator-title": true,
      "is-required": isAgeValid !== null ? !isAgeValid : false
    })

    const sliderLabelClassNames = classNames({
      "is-required": isAgeValid !== null ? !isAgeValid : false
    })

    return (
      <LanguageContext.Consumer>
        {({ currentLanguage, isTranslationLoaded, translationFn }) => {
          return (<div className="by-actual-age-calculator-container">
            <p className={titleClassNames}>
              {translationFn("tabs.byage.calculator.title")}
            </p>
            <div className='by-actual-age-calculator-slider-container'>
              <span className={sliderLabelClassNames}>{translationFn('tabs.byage.calculator.yearSliderLabelTxt')}</span>
              <ReactSlider
                className="by-actual-age-calculator-slider"
                min={this.props.minYearSliderValue}
                max={this.props.maxYearSliderValue}
                thumbClassName="by-actual-age-calculator-slider-thumb"
                trackClassName="by-actual-age-calculator-slider-track"
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                value={ageValue && ageValue.years ? ageValue.years : 0}
                onAfterChange={(value, _) => {
                  onAgeChanged({ years: value,
                    months: ageValue && ageValue.months ? ageValue.months : 0 }, 
                      (value) => value && (value.years > 0 || value.months > 0), null)
                }}
              />
            </div>
            <div className="by-actual-age-calculator-slider-container">
              <span className={sliderLabelClassNames}>{translationFn('tabs.byage.calculator.monthSliderLabelTxt')}</span>
              <ReactSlider
                className="by-actual-age-calculator-slider"
                min={this.props.minMonthSliderValue}
                max={this.props.maxMonthSliderValue}
                thumbClassName="by-actual-age-calculator-slider-thumb"
                trackClassName="by-actual-age-calculator-slider-track"
                disabled={isMonthSliderDisabled}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                value={ageValue && ageValue.months ? ageValue.months : 0}
                onAfterChange={(value, _) => {
                  onAgeChanged({ years: ageValue && ageValue.years ? ageValue.years : 0,
                    months: value }, (value) => value && (value.years > 0 || value.months > 0), null)
                }}
              />
            </div>
          </div>)
        }}
      </LanguageContext.Consumer>
    )
  }
}

export default ByActualAgePetAgeCalculator