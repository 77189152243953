import React from 'react'
import moment from 'moment'
import { LanguageContext } from '../localization/LanguageProvider'
import DatePicker from "react-datepicker";
import classNames from 'classnames'

class ByBirthDatePetAgeCalculator extends React.Component {
  static defaultProps = {
    calendarFormat: "yyyy-MM-dd",
    mappingFormat: "YYYY-MM-DD",
    minDate: moment().subtract(30, "y").toDate(),
    maxDate: moment().toDate()
  }

  render() {
    const { ageValue } = this.props
    const { isAgeValid } = this.props
    const { onAgeChanged } = this.props

    const datePickerFormFieldClassNames = classNames({
      'form-field': true,
      'is-required-form-field':  isAgeValid !== null ? !isAgeValid : false
    })

    const datePickerClassNames = classNames({
      'by-birth-date-calculator-datepicker': true
    })

    return (
      <LanguageContext.Consumer>
        {({ currentLanguage, isTranslationLoaded, translationFn }) => {
          return (<div className="by-birth-date-calculator-container">
            <div className={datePickerFormFieldClassNames}>
              <DatePicker
                className={datePickerClassNames}
                dateFormat={this.props.calendarFormat}
                locale={currentLanguage}
                selected={ageValue}
                onChange={(date) => {
                  onAgeChanged(
                    date,
                    this.validateBirthDate.bind(this),
                    this.mapBirthDate.bind(this)
                  )}}
                placeholderText={this.props.calendarFormat}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
              />
            </div>
          </div>)
        }}
      </LanguageContext.Consumer>
    )
  }

  validateBirthDate(date) {
    return moment(date).isValid()
  }

  mapBirthDate(date) {
    const birthDateAsMoment = moment(date)
    const today = moment()
    const diff = today.diff(birthDateAsMoment, "months")

    return { years: Math.trunc(diff / 12), months: diff % 12}
  }
}

export default ByBirthDatePetAgeCalculator