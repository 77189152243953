import React from 'react'

export const LanguageContext = React.createContext()

export class LanguageProvider extends React.Component {
    render() {
        return (
            <LanguageContext.Provider value={this.props.value}>
                {this.props.children}
            </LanguageContext.Provider>
        )
    }
}
