import React from 'react'

export const BreedContext = React.createContext()

export class BreedProvider extends React.Component {
    render() {
        return (
            <BreedContext.Provider value={this.props.value}>
                {this.props.children}
            </BreedContext.Provider>
        )
    }
}