import { Component } from "react";

class Header extends Component {
    render() {
        const { updateLanguageFn } = this.props

        return (
            <div className="header">
                <div className="logo-container">
                    <img className="logo-image" src="/img/logo.svg" />
                    <h1 className="logo-title">MyPetAge</h1>
                </div>
                <div className="i18n-container">
                    <ul className="languages">
                        <li className="language pt-BR" >
                            <img 
                                src="/img/language-pt-BR.svg" 
                                data-language="pt" 
                                onClick={(event) => { updateLanguageFn(event.target.getAttribute('data-language')) }}
                            />
                        </li>
                        <li className="language en-US">
                            <img 
                                src="/img/language-en-US.svg" 
                                data-language="en" 
                                onClick={(event) => updateLanguageFn(event.target.getAttribute('data-language'))}
                            />
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Header