import ReactDOM from 'react-dom'
import 'normalize.css';
import './index.css'
import LocalizedApp from './app/App'
import i18n from 'i18next'
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { registerLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-US'
import pt from 'date-fns/locale/pt-BR'


i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        }
    })

registerLocale('en', en)
registerLocale('pt', pt)

ReactDOM.render(<LocalizedApp useSuspense={false} />, document.getElementById('root'))