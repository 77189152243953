import { Component } from 'react'
import classNames from 'classnames'

class TabHeader extends Component {
    render() {
        const { id } = this.props
        const { title } = this.props
        const { isSelected } = this.props
        const { onTitleClicked } = this.props
        const { contentKey } = this.props
    
        const titleClassNames = classNames({
            'tab-header': true,
            'faded': !isSelected
        })

        return (
            <span
                id={id}
                className={titleClassNames}
                data-tab-content-key={contentKey}
                onClick={(event) => onTitleClicked(event.target)}
                data-selected={isSelected}
            >
                {title}
            </span>
        )
    }
}

export default TabHeader

