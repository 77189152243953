import React from 'react'
import { LanguageContext } from '../localization/LanguageProvider'
import ByActualAgePetAgeCalculator from './ByActualAgePetAgeCalculator'
import TabSwitcher from '../tabs/TabSwitcher'
import TabHeader from '../tabs/TabHeader'
import TabContent from '../tabs/TabContent'
import PetAgeCalculator from '../calculator/PetAgeCalculator'
import ByBirthDatePetAgeCalculator from '../calculator/ByBirthDatePetAgeCalculator'

class TabbedCalculator extends React.Component {
    render() {
        return (<LanguageContext.Consumer>
            {({ currentLanguage, isTranslationLoaded, translationFn }) => {
                return (isTranslationLoaded &&
                    <TabSwitcher>
                        <TabHeader
                            key="by-age-calculator-tab-header"
                            id="by-age-calculator-tab-header"
                            title={translationFn('tabs.byage.title')}
                            isSelected={true}
                            contentKey="by-age-calculator-tab-content"
                        />
                        <TabHeader
                            key="by-birth-date-calculator-tab-header"
                            id="by-birth-date-calculator-tab-header"
                            title={translationFn('tabs.bybirthdate.title')}
                            isSelected={false}
                            contentKey="by-birth-date-calculator-tab-content"
                        />
                        <TabContent
                            key="by-age-calculator-tab-content"
                            isSelected={true}
                        >
                            <PetAgeCalculator CalculatorComponent={<ByActualAgePetAgeCalculator hint={translationFn('tabs.byage.calculator.hint')} />}></PetAgeCalculator>
                        </TabContent>
                        <TabContent
                            key="by-birth-date-calculator-tab-content"
                            isSelected={false}
                        >
                            <PetAgeCalculator CalculatorComponent={<ByBirthDatePetAgeCalculator />}></PetAgeCalculator>
                        </TabContent>
                    </TabSwitcher>
                )
            }}
        </LanguageContext.Consumer>)
    }
}

export default TabbedCalculator