import classNames from "classnames"
import React, { Component } from "react"
import TabContent from "./TabContent"
import TabHeader from "./TabHeader"

class TabSwitcher extends Component {
    constructor(props) {
        super(props)

        const headers = this.props.children.filter(child => child.type === TabHeader)
        let defaultTabHeader = null
        let defaultTabIndex = null

        for(let i = 0; i < headers.length; i++) {
            if(headers[i].props.isSelected) {
                defaultTabHeader = headers[i]
                defaultTabIndex = i

                break
            }
        }

        this.state = {
            headers: headers,
            numberOfTabs: headers.length,
            selectedTabNumber: defaultTabIndex + 1,
            selectedTabKey: defaultTabHeader.props.id,
            selectedTabContentKey: defaultTabHeader.props.contentKey
        }
    }

    render() {
        const backButtonClassNames = classNames({
            'tab-headers-back-button': true,
            'tab-headers-button-disabled': this.state.selectedTabNumber === 1
        })

        const forwardButtonClassNames = classNames({
            'tab-headers-forward-button': true,
            'tab-headers-button-disabled': this.state.selectedTabNumber === this.state.numberOfTabs
        })

        return (
            <div className="tab-switcher">
                <div className="tab-headers">
                    <div className={backButtonClassNames} onClick={this.previousTab.bind(this)}/>
                    {this.props.children.filter(child => child.type === TabHeader).map(child => {
                        if (this.state.selectedTabKey === child.props.id) {
                            return React.cloneElement(child, { isSelected: true, onTitleClicked: this.onHeaderClicked.bind(this) })
                        }

                        return React.cloneElement(child, { isSelected: false, onTitleClicked: this.onHeaderClicked.bind(this) })
                    })}
                    <div className={forwardButtonClassNames} onClick={this.nextTab.bind(this)} />
                </div>
                <div className="tab-contents">
                    {this.props.children.filter(child => child.type === TabContent).map(child => {
                        if (this.state.selectedTabContentKey === child.key) {
                            return React.cloneElement(child, { isSelected: true })
                        }

                        return React.cloneElement(child, { isSelected: false })
                    })}
                </div>
            </div>
        )
    }

    onHeaderClicked(header) {
        this.setState({
            selectedTabKey: header.id,
            selectedTabContentKey: header.getAttribute('data-tab-content-key')
        })
    }

    nextTab() {
        const nextTab = this.state.selectedTabNumber < this.state.numberOfTabs ? this.state.selectedTabNumber + 1 : null

        if(nextTab) {
            this.setState({
                selectedTabNumber: nextTab,
                selectedTabKey: this.state.headers[nextTab - 1].props.id,
                selectedTabContentKey: this.state.headers[nextTab - 1].props.contentKey
            })
        }
    }

    previousTab() {
        const previousTab = this.state.selectedTabNumber > 1 ? this.state.selectedTabNumber - 1 : null

        if(previousTab) {
            this.setState({
                selectedTabNumber: previousTab,
                selectedTabKey: this.state.headers[previousTab - 1].props.id,
                selectedTabContentKey: this.state.headers[previousTab - 1].props.contentKey
            })
        }
    }
}

export default TabSwitcher